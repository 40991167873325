@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./css/common.css";
@import "./css/buttons.css";
@import "./css/navbar.css";
@import "./css/sidebar.css";
@import "./css/blockSidebar.css";
@import "./css/flowy.css";

:root {
  --paddingLeft: 84px;
  --sbWidth: 88px;
  --navHeight: 44px;
  --navHeightDouble: 88px;
  --navInactiveColor: #bcbcbc;
  --barBgColor: #fbfbfb;
}

body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}

* {
  outline: none !important;
}
button::-moz-focus-outer, button::-moz-focus-inner, button:active, button:focus, button {
  border: 0;
  outline: none !important;
}

.container-fluid {
  padding-left: 0;
  height: 100%;
}

.wrapper {
  display: flex;
  width: 100%;
}

#content {
  margin-left: var(--sbWidth);
  width: 100%;
  min-height: 100vh;
}

#page-body {
  padding-top: 28px;
  padding-left: var(--paddingLeft);
  padding-right: var(--paddingLeft);
  margin-top: var(--navHeight);
  height: 100%;
}

/*for Arch page ^^^^ add here*/
.nopadding {
  padding: 0 !important;
  /*margin-top: var(--navHeight);*/
  max-height: calc(100vh - var(--navHeightDouble));
  overflow: hidden;
}
